<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Inspection Element'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="equipment_id_label"
                  label="Equipment :"
                  label-for="equipment_id"
                >
                  <b-form-select
                    id="equipment_id"
                    v-model="$v.form.equipment_id.$model"
                    :options="equipment"
                    :state="validateState('equipment_id')"
                  ></b-form-select>

                  <b-form-invalid-feedback id="equipment_id_feedback">
                    Harap Memilih Salah Satu Equipment
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="part_id_label"
                  label="Part :"
                  label-for="part_id"
                >
                  <b-form-select
                    id="part_id"
                    v-model="$v.form.part_id.$model"
                    :options="part"
                    :state="validateState('part_id')"
                  ></b-form-select>

                  <b-form-invalid-feedback id="part_id_feedback">
                    Harap Memilih Salah Satu Part
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="method_label"
                  label="Metode :"
                  label-for="method"
                >
                  <b-form-input
                    id="method"
                    v-model="form.method"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="damage_mechanism_label"
                  label="Mekanisme Kerusakan :"
                  label-for="damage_mechanism"
                >
                  <vue-suggestion
                    id="damage_mechanism"
                    :items="damage_mechanism"
                    v-model="itemDamageMechanism"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputChange"
                    @selected="itemSelected"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                  >
                  </vue-suggestion>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="mode_kerusakan_label"
                  label="Mode Kerusakan :"
                  label-for="mode_kerusakan"
                >
                  <b-form-input
                    id="mode_kerusakan"
                    v-model="form.mode_kerusakan"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/master/inspectionplant')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import itemTemplate from '@/view/pages/item-template.vue'

export default {
  mixins: [validationMixin],
  name: 'addinspectionplant',
  data() {
    return {
      form: {
        equipment_id: null,
        part_id: null,
        method: null,
        damage_mechanism: null,
        mode_kerusakan: null,
      },
      equipment: [],
      part: [],
      damage_mechanism: [],

      itemDamageMechanism: null,
      itemTemplate,

      loading: false,
    }
  },
  validations: {
    form: {
      equipment_id: {
        required,
      },
      part_id: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Master', route: '/master/inspectionplant' },
      { title: 'Create Inspection Element' },
    ])
  },
  created() {
    var self = this

    ApiService.get('/master/equipment/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var equipment = data.data
          for (let i = 0; i < equipment.length; i++) {
            self.equipment.push({
              value: equipment[i].id_equipment,
              text: equipment[i].nama_equipment + ' (' + equipment[i].nomor_equipment + ')',
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })

    ApiService.get('/master/part/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var part = data.data
          for (let i = 0; i < part.length; i++) {
            self.part.push({
              value: part[i].id_part,
              text: part[i].nama_part,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      var self = this
      self.loading = true
      const equipment_id = self.$v.form.equipment_id.$model
      const part_id = self.$v.form.part_id.$model
      const method = self.form.method

      var damage_mechanism = null
      if (typeof self.form.damage_mechanism === 'object' && self.form.damage_mechanism !== null) {
        damage_mechanism = parseInt(self.form.damage_mechanism.id)
      } else {
        damage_mechanism = self.form.damage_mechanism
      }

      const mode_kerusakan = self.form.mode_kerusakan

      ApiService.post('/master/inspectionplant/store', {
        equipment_id: equipment_id,
        part_id: part_id,
        method: method,
        damage_mechanism: damage_mechanism,
        mode_kerusakan: mode_kerusakan,
      })
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/master/inspectionplant')
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
    setLabel(item) {
      return item.name
    },
    inputChange(text) {
      // your search method
      var self = this
      ApiService.get('/master/inspectionplant/showdamagemechanism/' + text)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var damage_mechanism = data.data
            self.damage_mechanism.splice(0, self.damage_mechanism.length)

            for (let i = 0; i < damage_mechanism.length; i++) {
              self.damage_mechanism.push({
                id: damage_mechanism[i].id_damage_mechanism,
                name: damage_mechanism[i].nama_damage_mechanism,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      this.form.damage_mechanism = text
    },
    itemSelected(item) {
      this.form.damage_mechanism = item
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
